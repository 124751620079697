#
# Exposes a sessionStorage based global object. Information stored in this global object is stored locally on the users
# disk. Maximum storage is 5MB. Please use sparingly as keeping scope based information in scope is usually preferred.
#
# Getting values:
#      var x = Globals.x
# Setting values:
#      Globals.x = 'value'
# Deleting values:
#      delete Globals.x
#
angular.module('fs.persistence').factory 'Session', ($rootScope, $sessionStorage, Globals) ->
    $rootScope.session = $sessionStorage.$default(Globals)
    return $rootScope.session
