# Removes html from text
#
# @example
#   'Hello <br/>. How are you?' | stripHtml
#     -> Hello. How are you?
#
angular.module('fs.filters').filter 'stripHtml', ->
    (str) ->
        return str if not str
        $('<div/>').html(str).text()
