angular.module('mc.dashboard', [])

.config ($routeProvider) ->
    $routeProvider
    .when '/',
        controller: 'DashboardController'
        template: 'dashboard.html'
    .otherwise
            redirectTo: '/'

# Loads the customers links and displays them on the homepage
#
.controller 'DashboardController', ($resource, Session) ->
    return undefined
