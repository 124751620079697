# Controller for a single configuration in the configurator. This controller has the responsibility to display error
# messages when an invalid configuration is used.
#
angular.module('mc.configurator').controller 'ConfigurationController', ($scope, $resource, $routeParams) ->

    _isReorder = $routeParams.hash? and $routeParams.code?

    # resource used to validate configuration and show error messages
    resource = $resource '/api/products/:code.json?format=configurator', {},
        params:
            code: '@code'
            pr08: '@pr08'

    # Contains all the information for the message that will be displayed
    #
    # @property [{product: (string|null), error: (string|null), price: (number|null)}]
    @message =
        product: null
        error: null
        price: null
        addition: null

   # Updates the message object
    @updateMessage = =>
        @message = resource.get
            code: _getProductCode()
            pr08: $scope.subject.profile.selected is 'PR08'
        $scope.pr08 = $scope.subject.profile.selected
        $scope.countChanged()

    $scope.$watch 'pr08', =>
        $scope.subject.profile.selected = $scope.pr08
        @updateMessage()

    # (private) method to get the product code for the currently selected items
    #
    # @returns [string|null] the product code or null if no selection item exists
    _getProductCode = =>
        return null if not $scope.subject?;
        return (if _isReorder then 'MCN-' else 'MC-') +
            $scope.subject.knob.selected +
            $scope.subject.a.selected +
            $scope.subject.b.selected +
            $scope.subject.finish.selected;

    # does not return
    return undefined
