#
# General purpose callout directive.
#
# This callout is based on the one used at the twitter bootstrap website. It is dismissible * and will stay dismissed in
# between sessions (the dismissal information is stored in the local storage).
#
angular.module('fs.callout').directive 'fsCallout', ->
  templateUrl: '/src/fs/callout/fs-callout.html'
  restrict: 'E'
  transclude: yes
  scope:
    title: '@'
    type: '@'
    dismissable: '='
  controller: 'fsCalloutController'
  controllerAs: 'vm'
  replace: no
  compile: (element, attrs) ->
    attrs.dismissable or= true