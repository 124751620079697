angular.module('mc.reorder').controller 'ReorderController', ($scope, FileUploader, Session, $location) ->

    @url = _url = ''
    @code = {}
    @step = 1;

    $scope.uploader = new FileUploader
        url: '/api/key_card.json?token=' + Session.currentUser?.authData
        autoUpload: yes
        onSuccessItem: (item, response) =>
            _url = response.replace /"/g, ''
            @url = '/api/key_card/' + _url + '?token=' + Session.currentUser?.authData
            @setStateTo 2

    $scope.$watch 'uploader', -> $scope.uploader.queue

    @send = =>
        console.log $scope.uploader
        return undefined

    @toOrder = (keysOnly = no) =>
        type = (if keysOnly then 'keyOnlyReorder' else 'fullReorder')
        url = 'configurator/' + type + '/' + @code.a + @code.b + @code.c + '/' + _url
        $location.path url


    $scope.$watch 'vm.code.c', => @setStateTo 3

    @setStateTo = (step) => @step = step if @preconditionMetFor step

    @preconditionMetFor = (step) =>
        switch step
            when 1 then return yes
            when 2 then return @url.length > 0 and _url.length > 0
            when 3 then return @code.c? and @code.c.length is 3
            else no


#    _url = '581384258cfab6f09a9a13640dcb0a4a3b779315.png'
#    @url = '/api/key_card/' + _url
#    @code = {a: 123, b: 234, c: 345}
    return undefined
