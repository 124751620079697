angular.module('mc.products', ['fs'])

.config ($routeProvider) ->
    $routeProvider

    # An overview of all orders for this customer
    .when '/manage/products',
        controller: 'ProductsController'
        controllerAs: 'vm'
        templateUrl: 'src/mc/products/product-overview.html'

    # Details of product page
    .when '/manage/products/:id',
        controller: 'ProductDetailsController'
        controllerAs: 'vm'
        templateUrl: 'src/mc/products/product-details.html'