# Handles the login form
#
angular.module('mc.login').controller 'LoginController', (Session, $window, $modal, RestAuthService, UsersService) ->

    # Reset login status
    auth = RestAuthService
    auth.ClearCredentials()

    # Set username, password and error fields to empty
    @username = @password = @errorMessage = @successMessage = ''

    # set flags to false
    @rememberLogin = @sendingMail = false

    # Expose a login function
    @login = =>
        auth.SetCredentials @username, @password, @rememberLogin
        auth.CheckLogin @username, @password
        .success (user) =>
            $window.location.href = if user.is_admin then '/admin/' else '/customer/'
        .error => @errorMessage = "Gebruikersnaam of wachtwoord incorrect."

    # Expose opening a lost password dialog
    @openLostPasswordDialog = =>
        dialog = $modal.open
            templateUrl: 'src/mc/login/modal.html'
            controller: 'LostPasswordController as vm'
        .result.then (mail) =>
            @mail = mail
            @sendingMail = true
            UsersService.mailResetToken(mail).then =>
                @successMessage = 'Een link om uw wachtwoord te wijzigen is naar ' + @mail + ' gestuurd.'
                @sendingMail = false

    # does not return
    return undefined