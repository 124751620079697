#
# Simple templates for a bootstrap based navigation
# @example
#   <navigation title="navTitle">
#       <item href="/item1">Item 1</item>
#       <item href="/item2">Item 2</item>
#       <item href="/item3">Item 3</item>
#   </navigation>
#
angular.module 'fs.navigation', []

# navigation directive
.directive 'navigation', ->
    templateUrl: 'src/fs/navigation/navigation.html',
    restrict: 'E',
    transclude: true,
    scope:
        title: '@'

# item directive
.directive 'item', ->
    templateUrl: 'src/fs/navigation/item.html',
    restrict: 'E',
    transclude: true,
    replace: true,
    scope:
        href: '@'
