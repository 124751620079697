angular.module('mc.sendType').controller 'SendTypeController', ($resource) ->

    res = $resource('/api/send_type/:id.json');

    @methods = res.query()
    @attributes = [
        {
            title: 'Code'
            attribute: 'code'
            sorter: 'code'
        }
        {
            title: 'Omschrijving'
            attribute: 'description'
            sorter: 'description'
        }
        {
            title: 'Prijs'
            expression: 'item.price | currency:\'€ \''
            sorter: 'price'
        }
        {
            title: ''
            link:
                text: 'Wijzig'
                icon: 'fa fa-cog'
                class: 'btn btn-default'
                url: '"#/manage/send-type/" + (item.id)'
        }
    ]

    return undefined
