#
# The Cart service.
#
# This service will handle all logic (locally in the local store, and remotely via the REST API) concerning a single
# customer's shopping cart. This service assumes that valid HTTP authorization headers are being send. For this we
# currently use fs.auth package.
#
angular.module('mc.cart').factory 'CartService', ($resource, Session, MessageService) ->
    new class CartService

        # The cart object used internally by this service
        #
        # @type $resource The REST API cart
        #
        cartApi = $resource '/api/shopping_cart/:id.json', {},
            query:
                method: 'GET'
            addItem:
                method: 'POST'
                params:
                    action: 'addItem'
            addSet:
                method: 'POST'
                params:
                    action: 'addSet'
                    pr08: '@pr08'
            checkout:
                method: 'POST'
                params:
                    action: 'checkout'


        # Get the cart from local storage.
        #
        # This does not load the cart from the REST service, but loads the
        # local copy. If you want to load from the rest service, you should use the Cart.load() function.
        #
        # @returns {$resource} The cart $resource object
        #
        cart: cartApi.query()

        # Add a product to the cart.
        #
        # This will add the product to the cart locally, and then send a POST to the REST service. The REST service
        # will respond with a new cart state which will update the current cart. In test setups this all happens
        # in < 50ms, so it will be hardly noticeable that the cart actually gets updated twice.
        #
        # @param  [Number] productId The database id of the product that should be added
        # @param  [Number] quantity  The number of times the product should be added
        # @param  [Number] price     The price of the item that is going to be added (used for the initial update only)
        # @param  [String] code      The product code for this item
        # @return [Promise]          The cart after adding
        #
        add: (productId, quantity, price, code) ->

            # add locally
            @cart.count = (parseInt @cart.count) + (parseInt quantity)
            @cart.bruto_cost = (parseFloat @cart.bruto_cost) + (parseFloat price * quantity)
            @cart.$resolved = false

            # add remotely
            promise = cartApi.addItem({id: productId, quantity: quantity}).$promise
            promise.then (result) =>
                @cart = result
                MessageService.clear()
                MessageService.add 'Product ' + code + ' ' + quantity + 'x toegevoegd aan winkelwagen', 'success'

            return promise

        # Removes the given item from the cart locally and then remotely. Will update the carts as soon as the new state
        # has been received from the remote api.
        #
        # @param  [Number]  id    The orderItem id of the item to remove
        # @param  [Number]  index The current index of the item to remove
        # @return [Promise]       The updated cart
        #
        remove: (id, index) ->
            item = @cart.order_items[index]

            # remove locally
            @cart.count = (parseInt @cart.count) - item.quantity
            @cart.bruto_cost = (parseFloat @cart.bruto_cost) - (parseFloat item.price * item.quantity)
            @cart.$resolved = false
            @cart.order_items.splice index, 1

            # remove remotely
            promise = cartApi.remove({id: id}).$promise
            promise.then (result) => @cart = result

            # return the cart promise
            return promise

        # Adds a array of products to the cart.
        #
        # In contrast to the normal update function, this will not do a local update first. It will only use the
        # returned server state for update. This is because after an addAll update, the user is usually redirected
        # to a result page anyway.
        #
        # @param arrayOfObjects An array of products to add. It should be in the following form
        #                       [{code: "foo", quantity: 1}, ..., {code: "bar", quantity: 99}]
        # @param numTimes int   number of times to order the set
        #
        addSet: (arrayOfObjects, numTimes, pr08 = no, reorder = no) ->
            @cart.$resolved = false
            promise = cartApi.addSet({times: numTimes, pr08: pr08, reorder: reorder}, arrayOfObjects).$promise
            promise.then (result) =>
                @cart = result
                MessageService.add numTimes + ' gelijksluitende sets toegevoegd', 'success' if numTimes > 1
                MessageService.add 'Gelijksluitende set toegevoegd', 'success' if numTimes == 1
            return promise

        # checkouts the shopping cart. Placing it for inspection at the backend.
        #
        # @param   [Object]        data            The additional data given to the api when checking out the cart.
        # @options [Number]        sendMethodId    The id of the sendmethod that should be used.
        # @options [Array<String>] shippingAddress The address to which to ship.
        # @options [String]        reference       Optional reference given upon checkout
        # @return  [Promise]                       The new (empty) cart which is generated after a successful checkout.
        #
        checkout: (data) ->
            promise = cartApi.checkout(data).$promise
            promise.then (result) => @cart = result
            return promise

        # Get the cart from the REST Api.
        #
        # This will request the state of the cart from the REST Api. Calling this function updates the cart in the
        # local space for future use and also returns the result for direct use.
        #
        # @return {$resource} The cart $resource object
        #
        refresh: -> cartApi.query().$promise.then (result) => @cart = result
