# Directive for making a link that can manipulate the sorting of some externally defined source.
#
angular.module('fs.table').directive 'fsSorter', ->
    templateUrl: 'src/fs/table/fs-sorter.html'
    restrict: 'A'
    transclude: true
    scope:
        attribute: '@' # The external attribute to sort
        predicate: '=' # The current attribute sorted on
        descending: '=' # Flag indicating whether to sort ascending (when false) or descending (when true)
    controller: ($scope) ->
        $scope.flip = ->
            return $scope.descending = !$scope.descending if $scope.attribute is $scope.predicate
            return $scope.descending = false

