# Controller that fetchers account and exposes it to the template.
# Also has password changing functionality
#
angular.module('mc.users').controller 'UsersController', (UsersService) ->

    @customers = UsersService.getAllCustomers()

    @attributes = [
        {
            sortable: no
            attribute: 'relation_number'
            title: 'Relatie #'
        }
        {
            attribute: 'company'
            title: 'Bedrijf'
        }
        {
            attribute: 'contact_person'
            title: 'Contact'
        }
        {
            attribute: 'phone'
            title: 'Telefoon'
        }
        {
            attribute: 'mail'
            title: 'Email'
        }
        {
            title: ''
            link:
                icon: 'fa fa-cog'
                text: 'Wijzig'
                class: 'btn btn-default'
                url: '"#/manage/users/" + (item.id)'
        }
    ]

    # does not return
    return undefined

