# Controller for order detail page.
#
# Displays the content of a single order. When the order is not yet checked, the end-user
# (administrator) is able to apply a check, delete, or place order back to shopping cart.
#
angular.module('mc.orders').controller 'OrderDetailsController',
(OrdersService, $routeParams, $location) ->

    # Extract the order id to get details for
    id = $routeParams['id']

    # Gets the single order details
    @order = OrdersService.get(id)

    # Returns true if checked normal and checked custom are both non-null
    @isChecked = => @order.checked_normal? and @order.checked_custom?

    # returns the name of the check attribute that is null.
    # When both check attributes are non-null, this function will return null.
    # So for an order that has not yet passed normal check: 'check_normal' is returned.
    #  and for an order that passed all checks: NULL is returned.
    @checkAttr = =>
        if not @order.checked_normal? then return 'checked_normal'
        else if not @order.checked_custom? then return 'checked_custom'
        else return null

    # Sets the check attributes of this object to NOW (which means that the are considered just
    # checked) and requests the API to save this update.
    @check = =>
        @order.checked_normal = new Date()
        @order.checked_custom = new Date()
        OrdersService.check(@order.id).$promise


    # Sets the check attributes of this object to NOW (which means that the are considered just
    # checked) and requests the API to save this update. Additionally, thereafter, the next
    # unchecked order is loaded
    @checkAndNext = =>
        currentAttr = @checkAttr()
        currentType = 'normal' if currentAttr is 'checked_normal'
        currentType = 'custom' if currentAttr is 'checked_custom'
        @check().then =>
            @gotoNext(currentType)

    # Changes the path to the next order to check, or to the overview if no such order exists
    @gotoNext = (type) =>
        OrdersService.getAllUnchecked(type).$promise.then (res) =>
            id = res[0]?.id
            id_q = id?
            if id? then $location.path '/manage/orders/' + id
            else $location.path '/check/' + type

    # Deletes this order and returns it's contents to the shopping cart.
    @returnToCart = =>
        @order.$returnToCart().then (result) =>
            $location.path '/manage/orders/'

    # Deletes this order. The order is lost by this action.
    @removeOrder = =>
        @order.$delete().then (result) =>
            $location.path '/manage/orders/'

    # controller does not return anything meaningful
    return undefined
