# The M&C Cart module. This module contains all the logic concerning the shopping cart.
#
# This includes the "info card" at the top of the page, the cart details page to which an user can go as well as
# logic concerning that page. For cart related logic, this module also exposes a cart service, which can be used by
# other controllers.
#
angular.module 'mc.cart', ['ngResource', 'ngRoute', 'fs.editable']

# Exposes the routes this module manges. At the moment for the cart module, this is only the /cart route, which
# contains a detailed overview of everything that is in the cart, as well as the possibility to checkout the cart.
#
.config ($routeProvider) ->
    $routeProvider.when '/cart',
        controller: 'CartController',
        controllerAs: 'vm',
        templateUrl: 'src/mc/cart/overview.html'
