# Contains information about the user, as well as editing and creation capabilities for users.
#
angular.module('mc.users', ['ngRoute', 'ngResource', 'ui.bootstrap'])

# Expose the user detail page at the /profile link
.config ($routeProvider) ->
    $routeProvider

    # view own user account details
    .when '/account',
        controller: 'AccountController'
        controllerAs: 'vm'
        templateUrl : '/src/mc/users/user-detail.html'

    # Overview of all users
    .when '/manage/users',
        controller: 'UsersController'
        controllerAs: 'vm'
        templateUrl: '/src/mc/users/users-overview.html'

    # Manage and edit user details
    .when '/manage/users/:id',
        controller: 'EditUserController'
        controllerAs: 'vm'
        templateUrl: '/src/mc/users/user-edit.html'

    # Add new user
    .when '/add/user',
        controller: 'AddUserController'
        controllerAs: 'vm'
        templateUrl: '/src/mc/users/add-user.html'