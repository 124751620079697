angular.module('mc.sendType').controller 'SendTypeDetailsController', ($resource, $routeParams) ->

    # Fetch the user id of the user to be viewed
    id = $routeParams['id']

    # Resource used for getting and updating send type
    # should be put own service if we want to add() and delete() as well (= cleaner)
    res = $resource '/api/send_type/:id.json', {},
        update:
            method: 'PUT'
            params:
                id: '@id'


    @method = res.get({id: id})

    @update = => @method.$update()

    @toggleIsCollection = =>
        @method.is_collection = !@method.is_collection
        @method.$update()

    return undefined
