# The directive for a drop down box.
#
# change() should be called when the value of the drop down changes.
#
# Subject expects the following format:
# ```json
# {
#   options: {
#      "a": [1,2,3]
#      "b": ["a","b","c"]
#   }
#   selected: {
#      "a": 1
#      "b": "c"
#   }
# }
# ```
#
angular.module('mc.configurator').directive 'mcDropDown', ->
    templateUrl: 'src/mc/configurator/mc-drop-down.html'
    restrict: 'E'
    scope:
        title: '@'
        subject: '='
        change: '&'
        lang: '='