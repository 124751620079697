# Filters out all the elements from the given array with index lower then the given start index
#
angular.module('fs.paginate').filter 'startFrom', ->

    # Filters out all the elements from the given array with index lower then the given start index
    #
    # @param [Array] input  The input to filter
    # @param [Number] start The index of the first element in the filtered array
    # @return               The sub array starting at index "start"
    #
    (input, start) ->
        start = parseInt start
        input.slice(start)