# Common elements module.
#
# The purpose of this module is to define some reusable parts of the created AngularJS based application. Each part
# of this framework should be documented extra carefully and should make sure that there is no dependency on the current
# project.
#
angular.module 'fs', [
    'fs.address'
    'fs.auth'
    'fs.callout'
    'fs.editable'
    'fs.popup'
    'fs.navigation'
    'fs.paginate'
    'fs.persistence'
    'fs.readFile'
    'fs.table'
    'fs.toggle'
    'fs.util'
    'fs.filters'
]
