# Service for managing the extra keys that can be ordered in the configurator. Manages the extra key scope as well as
# handles the creation and deletion of such keys.
#
angular.module('mc.configurator').factory 'ExtraKeysService', ($http, Session) ->
    class ExtraKeysService

        # Initializes a new instance of the extra key service
        constructor: ->
            @nextId = 1
            @current = {}

        # add an extra key to the internal scope to the current extra keys
        #
        # @return [$http] The request for adding the extra key
        #
        add: ->
            request = $http.get 'assets/configurator-keys-extra.json'
            request.success (data) =>
                id = @nextId++
                data = (data.map (obj) -> { text: obj.text[Session.language], value: obj.value })
                @current[id] =
                    options: data
                    id: id
                    selected: data[0].value
                return @current
            return request

        # Remove the extra key configuration with the given key configuration from the internal scope
        #
        # @param [Number] id The id of the extra key configuration to remove
        #
        remove: (id) ->
            delete @current[id];
            return undefined

        # Transforms the current selection to a format that can be parsed to json
        #
        # @return [Array<String, Number>] The format understood by the Api
        #
        getResult: ->
            keys = for id, keyConfig of @current
                code: keyConfig.selected
            keys = keys.reduce (x, y) ->
                x[y.code] ?= 0
                x[y.code]++
                x
            , {}
            keys = for x, y of keys
                code: x
                quantity: y
