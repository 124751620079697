# Handles all placed order related content.
# This handles previous placed orders, checking placed orders
# Does not handle the shopping cart.
#
angular.module('mc.orders', ['ui.bootstrap']).config ($routeProvider) ->
    $routeProvider

    # an overview of all orders for this customer
    .when '/orders',
        controller: 'OrdersController'
        controllerAs: 'vm'
        templateUrl: 'src/mc/orders/order-overview.html'

    # details of a specific order
    .when '/orders/:id',
        controller: 'OrderDetailsController'
        controllerAs: 'vm'
        templateUrl: 'src/mc/orders/order-details.html'

    # check orders (of type: type)
    .when '/check/:type',
        controller: 'CheckOrdersController'
        controllerAs: 'vm'
        templateUrl: 'src/mc/orders/order-check.html'

    # manage all placed orders
    .when '/manage/orders',
        controller: 'AdminOrdersController'
        controllerAs: 'vm'
        templateUrl: 'src/mc/orders/order-manage.html'

    # manage a specific order
    .when '/manage/orders/:id',
        controller: 'OrderDetailsController'
        controllerAs: 'vm'
        templateUrl: 'src/mc/orders/order-details-admin.html'
