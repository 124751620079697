# The message service. This service can be used to display quick informative popup messages to the user.
#
#
#
angular.module('fs.popup').service 'MessageService', ($rootScope, $timeout, Hash) ->

    # How long to display the message for (in ms)
    removeAfterMs = 2200

    # The message being displayed
    class Message
        constructor: (title, body, type) ->
            @title = title
            @body = body
            @type = type
            @$hash = Hash.create()

    # The message service class that keeps the stack of displayed messages
    new class MessageService
        constructor: ->
            @content = []

        # Add a message to the display stack.
        #
        # @param [String] body  The text of the message
        # @param [String] type  The type of message (info, success, warning, danger)
        # @param [String] title The message title, do not set if no title required
        #
        add: (body, type, title) ->
            msg = new Message(title, body, type)
            @content.push msg
            @removeDelayed msg, removeAfterMs
            return undefined

        # Get the index in the stack of the given message object, or -1 if none.
        #
        # @param [Message] message The message to get the index of.
        # @return [Number]         The index of the message or -1 if no such message exists.
        #
        indexOf: (message) ->
            for msg, index in @content
                if (msg.$hash == message.$hash)
                    return index
            return -1

        # Removes the given message from the message stack.
        #
        # @param [Message] message The message to remove.
        #
        remove: (message) -> @content.splice (@indexOf message), 1

        # Removes the given message from the massage stack.
        #
        # @param [Number] index The index of the message to remove.
        #
        removeByIndex: (index) -> @content.splice index, 1

        # Removes the given message from the message stack after the given delay.
        #
        # @param [Message] message The message to remove.
        # @param [Number] delay The number of milliseconds after which to remove the message.
        removeDelayed: (message, delay) -> $timeout (=> @remove message), delay

        # Empties the message stack so that there are no more messages to display
        #
        clear: -> @content = []
