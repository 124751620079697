# The configurator main controller. This controller has the responsibility to keep track of all the configurations
# and aggregate all the configurations' settings into a API submittable json string.
#
angular.module('mc.configurator').controller 'ConfiguratorController',
(ConfigurationsService, CartService, ExtraKeysService, Session, $location, $routeParams) ->

    # state is one of the following: normal|fullReorder|keyOnlyReorder
    @state = if $routeParams.type then $routeParams.type else 'normal'

    @reorder = {}
    @reorder.card = '/api/key_card/' + $routeParams.hash + '?token=' + Session.currentUser?.authData if $routeParams.hash?
    @reorder.code = $routeParams.code if $routeParams.code?
    _reorder = $routeParams.code + $routeParams.hash if $routeParams.hash? and $routeParams.code?

    # Holds the Configurations service
    @configs = new ConfigurationsService()
    @configs.add() unless @state is 'keyOnlyReorder'

    # Holds the extra keys service
    @keys = new ExtraKeysService()
    @keys.add() if @state is 'keyOnlyReorder'

    # The number of times to order the configuration
    @count = 1

    # either 'DEFAULT' or 'PR08', indicating normal or security cylinders
    @pr08 = 'DEFAULT'

    # flips @pr08: 'DEFAULT' <--> 'PR08'
    @flipPr08 = =>
        return @pr08 = 'DEFAULT' if @pr08 = 'PR08'
        return @pr08 = 'PR08' if @pr08 = 'DEFAULT'


    # Flag to indicating whether this controller is currently submitting to the api
    @submitting = false

    # Gets a json serializable array of all the items in the current configuration
    @getResult = => @configs.getResult(_reorder?).concat @keys.getResult()

    # Submits the configuration to the cart and then redirects to the cart
    @submit = =>
        @submitting = true
        CartService.addSet @getResult(),
            @count,
            @pr08 is 'PR08',
            _reorder
        .then =>
            @submitting = false
            $location.path '/cart'

    # does not return
    return undefined
