# Converts every word in the string to start with an uppercase letter.
# Uses a default threshold for not capitalizing small words, but can be overwritten
#
# @example
#   'this is the title of a chapter' | capitalizeWords
#   -> 'This is the Title of the Chapter'
#
# @example
#   'this is the title of a chapter' | capitalizeWords:false
#   -> 'This Is The Title Of The Chapter'
#
angular.module('fs.filters').filter 'capitalizeWords', ->
    (str = '', exceptionSize = 4) ->
        ucFirst = (word) -> word.charAt(0).toUpperCase() + word.slice(1)
        words = str.toLowerCase().split(' ')
        words = for word, index in words
            isStartOrEnd = index is 0 or index is words.length - 1
            isException = isFinite(exceptionSize) and word.length < exceptionSize
            if isException and not isStartOrEnd then word else ucFirst(word)
        return words.join(' ')
