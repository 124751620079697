# Directive for changing password inline
#
angular.module('mc.users').directive 'mcUserAddress', ->
    templateUrl: 'src/mc/users/mc-user-address.html'
    restrict: 'A'
    replace: true
    scope:
        account: '=mcUserAddress'
        edited: '&'
        isEditable: '='
    link: (scope, element, attrs) ->

        scope.editing = false

        scope.edit = =>
            scope.editing = true
            scope.orginal = angular.copy(scope.account)
            return undefined

        scope.ok = (newVal) =>
            scope.editing = false
            scope.edited()
            return undefined

        scope.clear = =>
            scope.account = scope.orginal
            scope.editing = false
            return undefined

        return undefined