# Controller that fetchers account and exposes it to the template.
# Also has password changing functionality
#
angular.module('mc.users').controller 'AccountController',
(UsersService, $timeout, $window, OverlayService) ->

    # get logged in user
    @account = UsersService.getLoggedInUser()

    # holds the currently typed password
    @pass = {}

    # function for changing password
    @changePassword = =>
        OverlayService.setAndShow [
            'Wachtwoord Gewijzigd'
            'Uw wachtwoord is gewijzigd, u wordt nu teruggestuurd naar de login pagina.'
        ]...
        UsersService.changePassword @pass.new
            .$promise.then => $timeout (-> OverlayService.hide(); $window.location.href = '/'), 2200

    # does not return
    return undefined

