angular.module('fs.address').factory 'Address', ($resource) ->
    new class Address

        #
        # http://api.postcodeapi.nu/docs/
        #
        res = $resource 'http://api.postcodeapi.nu/:postalCode', {},
            getAddress:
                method: 'GET'
                params:
                    postalCode: '@postalCode'
                withCredentials: no
                headers:
                    'Authorization': -> null
                    'Accept': -> null
                    'Api-Key': '1654a1b205cd097927d8931a81d1680fbbf9e62a'
                transformResponse: (data, headers) ->
                    return unless data?.length
                    json =  JSON.parse data
                    {
                        street: json.resource.street
                        city: json.resource.town
                        postal_code: json.resource.postcode
                    }


        get: (postalCode) ->
            res.getAddress {postalCode: postalCode}