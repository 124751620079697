# Login module is the first module called, it will handle the login screen and all functionality needed to log in
#
angular.module 'mc.login', [
    'fs.auth'
    'ngRoute'
    'fs.persistence'
    'ui.bootstrap'
    'mc.users'
    'mc.common'
]

# Exposes the /logout link as well as the /reset-password link (which is called when a lost password email link is
# clicked.
#
.config ($routeProvider) ->
    $routeProvider
        .when '/',
            controller: 'LoginController'
            controllerAs: 'vm'
            templateUrl: 'src/mc/login/index.html'
        .when '/reset-password/:token',
            controller: 'ResetPasswordController'
            controllerAs: 'vm'
            templateUrl: 'src/mc/login/reset-password.html'
        .when '/logout',
            controller: 'LogoutController'
            template: ''