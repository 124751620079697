#
# Controller for callout directive.
#
# This controller will try to load localStorage to see if the callout would be shown. It exposes a toggleDialog()
# function which will toggle between showing the dialog and hiding it as well as a showDialog variable which is either
# true when dialog should be shown, or false if not.
#
angular.module('fs.callout').controller 'fsCalloutController', (Globals,  $route, $scope) ->

    # A flag to indicate whether the user should be able to dismiss the callout. Will default to true.
    @dismissable = $scope.dismissable ? true

    # Type of callout to show. Will default to an info callout
    @type = $scope.type ? 'info'

    # retrieve current template and local storage for dialog showingness
    templateUrl = $route.current.templateUrl;

    # retrieve the show dialogs variable
    showDialogs = Globals.showDialogs ? {}

    # If not defined, initialize to true (show dialog)
    showDialogs[templateUrl] ?= true

    # Save showDialogs back to the globals
    Globals.showDialogs = showDialogs;

    # Boolean variable to indicated whether dialog should be shown
    # @type boolean true if dialog should be shown
    @showDialog = showDialogs[templateUrl];

    # toggles the showDialog variable between true and false
    @toggleDialog = =>
        Globals.showDialogs[templateUrl] = !showDialogs[templateUrl]
        @showDialog = showDialogs[templateUrl]
        undefined

    # no return value
    return undefined