# Configurations service for the mc configurator.
#
# This service contains all the configurations for the configurator. It also exposes convenient add() and remove()
# methods to manipulate the number of configurations. This service should also calculate and expose all the functions
# that can be deduced from the selected configurations and is needed in the configuration module.
#
angular.module('mc.configurator').factory 'ConfigurationsService', ($http, Session) ->
    class ConfigurationsService

        # Initializes a new instance of the Configurations class.
        # This will also fetch the default keys and add an initial configuration
        #
        # @return [$http] the request to add the initial configuration
        #
        constructor: ->
            @nextId = 1
            @current = {}
            @keySource = null
            $http.get('assets/configurator-keys-default.json').success (data) => @keySource = data


        # Add an extra configuration
        #
        # @return [$http] the request for adding an extra configuration
        #
        add: (reorder = no) ->
            request = $http.get 'assets/configurator.json'
            request.success (data) =>
                id = @nextId++
                @current[id] = data
                @current[id].id = id
                @current
            return request


        # Remove configuration with given id
        #
        # @param [Number] id The id to remove
        #
        remove: (id) ->
            delete @current[id];
            return undefined


        # Gets the default keys for this config.
        # If you order a certain amount of cylinders, you get free keys with them. This function will return the colors of
        # the keys that are default for the given amount of cylinders.
        #
        # @return [Array<String>] The colors of the keys
        #
        getKeys: ->
            count = @getCylinderCount()
            @_getKeys(count)

        # Gets an array of objects which can be used to pass on to the Api.
        #
        # @return [Array<String, Number>] Api readable object
        #
        getResult: (reorder = no) ->
            for id, config of @current
                code: @_getProductCode(config, reorder),
                quantity: config.count.selected

        # Gets the number of cylinders currently selected
        #
        # @return [Number] The number of cylinders
        #
        getCylinderCount: ->
            cylinderCount = 0
            for id, config of @current
                cylinderCount += config.count.selected
            return cylinderCount

        # Checks whether this configuration is valid
        # Currently it only checks if all `count` objects are set. which shouldn't be the case when an error occurs for
        # that specific option
        #
        # @return [Boolean] True if this configuration is in a valid state
        #
        isValid: ->
            for id, config of @current
                return false if not config.count.selected > 0
                return false if (config.a.selected > 52 or config.b.selected > 52) and config.finish.selected == 'B'
                return false if config.b.selected == 10 and config.knob.selected == 'E'
            return true

        # (private) get the product code for the given configuration
        #
        # @param  [Object] config The configuration to get the string of
        # @return [String]        The product code
        #
        _getProductCode: (config, reorder = no) ->
            (if reorder then 'MCN-' else 'MC-') +
                config.knob.selected +
                config.a.selected +
                config.b.selected +
                config.finish.selected;

        # (private) gets the keys for the given number of cylinders
        #
        # @param  [Number] count  The number of cylinders to find the default keys for
        # @return [Array<String>] An array of the default key's colors
        #
        _getKeys: (count) ->
            return [] if count <= 0 or not @keySource?
            keys = @keySource.default[count]
            return @_getKeys(count-1) if not keys?
            return keys.map (index) => @keySource.keys[index].text[Session.language]
