# Controller that handles the lost password modal
#
angular.module('mc.login').controller "LostPasswordController", ($modalInstance) ->

    # Holds the email address typed in the email field of the lost password modal
    @email = ""

    # Cancels this modal and forgets the email
    @cancel = => $modalInstance.dismiss 'cancel'

    # Close this modal and resolve it using the typed email adres
    @ok = => $modalInstance.close @email

    # does not return
    return undefined