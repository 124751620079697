# This module will enable the developer to show informative popup messages to the user.
# @example
#   index.html:
#       ...
#       <body>
#         <fs-messages></fs-messages>
#         ...
#       </body>
#       ...
#
#   controller:
#       MessageService.add('message body', 'info')
#
angular.module('fs.popup', ['ui.bootstrap'])
