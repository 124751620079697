# Filter for formatting string in C's printf() function style
#
# @example
#   'Hello %s. What are you been doing this %s?' | printf : 'Sam' : 'evening'
#    -> Hello Sam. What are you begin doing this evening?
#
angular.module('fs.filters').filter 'printf', ->
    (str, args...) ->
        return str if not str or args.length is 0
        for i in [0..args.length]
            regex = new RegExp '\\{' + i + '\\}', 'gm'
            str = str.replace regex, args[i]
        return str
