# Directive for the mc-cart-info tag.
#
# The cart info will show number of articles and total bruto cost. Also it gives a indication whether it is up to date
# (darker) or updating (lighter)
#
angular.module('mc.cart') .directive 'mcCartInfo', ->
    templateUrl : 'src/mc/cart/mc-cart-info.html'
    restrict: 'E'
    controller: 'CartController'
    controllerAs: 'vm'
