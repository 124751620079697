# The M&C Key Configurator module. This module contains all logic for ordering custom key blanks
#
# This is not the generic configurator described elsewhere in the code, but one specifically for ordering key blanks.
#
angular.module 'mc.keyConfigurator', []

# Expose the the top level page at the /configurator link and link the ConfiguratorController to it.
.config ($routeProvider) ->
    $routeProvider.when '/key-configurator',
        controller  : 'KeyConfiguratorController',
        controllerAs: 'config',
        templateUrl : 'src/mc/keyConfigurator/KeyConfigurator.html'
