# Generates html given an email address
#
# @example
#   info@m-c.eu | email
#     -> <a href="mailto:info@m-c.eu">info@m-c.eu</a>
#
# @example
#   info@m-c.eu | email:'help desk'
#     -> <a href="mailto:info@m-c.eu">help desk</a>
#
angular.module('fs.util').filter 'email', ->
    (input, display = input) -> '<a href=\"mailto:' + input + '\">' + display + '</a>'
