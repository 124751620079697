# Service that exposes the orders stored in the backed
#
angular.module('mc.orders').factory 'OrdersService', ($resource) ->
    new class OrdersService

        # the $resource used for backend api calls
        api = $resource '/api/orders/:id.json', {},
            getAll:
                method: 'GET'
                isArray: yes
                params:
                    checked: '@checked'
                    unchecked: '@unchecked'
                    exported: '@exported'
            check:
                method: 'PUT'
                params:
                    id: '@id'
                    action: '@action'
                    type: '@type'
            delete:
                method: 'DELETE'
                params:
                    id: '@id'
            returnToCart:
                method: 'DELETE'
                params:
                    id: '@id'
                    action: 'returnToCart'

        # Gets the details of a specific order
        #
        # @param  [Number] id The id of the order to get the details of
        # @return [$resource] The order details
        #
        get: (id) ->
            api.get({id: id})

        # Gets all the orders (that are visible by this account)
        #
        # @param [Object] options Optional filters
        # @option [Boolean] checked True: show only checked orders, False: show only unchecked
        #   orders
        # @option [String] unchecked 'normal': show orders not checked normal, 'custom': show orders
        #   not checked custom
        # @option [Boolean] exported True: show only exported orders, False: show only not exported
        #   orders
        # @return [$resource] An array of all orders
        #
        getAll: (options) ->
            api.query(options)

        # Check (apply check mark) the given order
        #
        # @param  [Number]    id    The id of the order to check
        # @param  [String]    type  Type of check to apply (either 'normal' or 'custom')
        # @param  [Boolean]   check If set to true, order is checked, if set to false, order is
        #   unchecked
        # @return [$resource]       The api result of this call
        check: (id, type = 'both', check = yes) ->
            api.check
                id: id
                action: if check then 'check' else 'uncheck'
                type: type
