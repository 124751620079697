angular.module('mc.files', ['angularFileUpload'])

.config ($routeProvider) ->
    $routeProvider

    .when '/files',
        controller: 'FilesCtrl'
        controllerAs: 'vm'
        templateUrl: 'src/mc/files/files-readonly.html'

    .when '/files/:path*',
        controller: 'FilesCtrl'
        controllerAs: 'vm'
        templateUrl: 'src/mc/files/files-readonly.html'

    .when '/manage/files/',
        controller: 'FilesCtrl'
        controllerAs: 'vm'
        templateUrl: 'src/mc/files/files.html'

    .when '/manage/files/:path*',
        controller: 'FilesCtrl'
        controllerAs: 'vm'
        templateUrl: 'src/mc/files/files.html'
