# Directive for an editable text field.
#
# The text field has two modi: When nog editing, it is represented by a label and when in editing mode, it will be
# represented by a input field.
#
angular.module('fs.editable').directive 'fsEditableRange', ->
    restrict: 'EA'
    templateUrl: 'src/fs/editable/fs-editable-range.html'
    scope:

        # The start of the range for calculation
        from: '='

        # the end of the range for calculation
        to: '='

        # The start of the range for presentation
        fromExpr: '@'

        # The end of the range for presentation
        toExpr: '@'

        # callback when enter is pressed
        submit: '&onSubmit'

