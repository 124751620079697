# The cart controller. This controller loads the user's cart if hideMenus is not active (which it would be if the user
# is currently at login page).
#
angular.module('mc.cart').controller 'CartController', (Session, CartService, UsersService) ->

    # exposes the cart service to the template
    @service = CartService

    # Removes an item from the cart
    #
    # @param  [Number] id    The OrderItem id of the item to remove
    # @param  [Number] index The index of the item to remove
    # @return [Promise]      The update cart
    #
    @remove = (id, index) => @service.remove(id, index)

    # Holds the current user
    @user = {}

    # Holds the shipping address
    @shippingAddress = {}

    # Holds the authdata (needed for keycard api calls)
    @authData = Session.currentUser?.authData

    # Holds the reference and the maximum number of characters in a reference
    @ref = {current: null, max: 40}

    # Get the send type of the given send type id
    #
    # @param [Number] id The id of the send type to look up
    # @return [Object]   The send type
    #
    getSendType = (id) =>
        for __, value of @service.cart.send_options
            return value if value.id is id

    # Changes the send type to the given send type id
    #
    # @param [Number] id The id of the send type to change to
    #
    @changeSendType = (id) =>
        type = getSendType(id)
        @service.cart.send_type = type
        @service.cart.send_cost = parseFloat type.price
        @service.cart.cost = (parseFloat @service.cart.cost) + (parseFloat type.price)

    # Checkout the cart
    #
    # @return [Object] Empty cart after successful checkout
    @checkout = =>
        @service.checkout
            sendMethodId: @service.cart.send_type.id
            shippingAddress: @shippingAddress
            reference: @ref.current

    # Reset the shipping address to the billing address
    #
    #
    @resetShippingAddress = =>
        @shippingAddress =
            company: @user.company
            street: @user.street
            house_number: @user.house_number
            house_number_addition: @user.house_number_addition
            postal_code: @user.postal_code
            city: @user.city
            country: @user.country


    # initialize user and shipping address
    UsersService.getLoggedInUser().$promise.then (user) =>
        @user = user
        @shippingAddress =
            company: user.company
            street: user.street
            house_number: user.house_number
            house_number_addition: user.house_number_addition
            postal_code: user.postal_code
            city: user.city
            country: user.country

    # this controller does not return anything
    return undefined
