# Controller for overview of orders in admin area.
#
# This is only a landing page, which will direct the end-user (administrator) to the correct order.
#
angular.module('mc.orders').controller 'AdminOrdersController', (OrdersService, $routeParams) ->

    # Gets all orders visible by this user
    @orders = OrdersService.getAll()

    # Sets checked to true if this order is both checked normal and custom
    @orders.$promise.then (orders) =>
        for order in orders
            order.checked = order.checked_normal? && order.checked_custom?

    # Data used to generate table in html view
    @attributes = [
        {
            title: 'Datum'
            expression: 'item.placed | date: "short"'
            sorter: 'placed'
        }
        {
            title: 'Bedrijf'
            expression: 'item.user.company'
            sorter: 'user.company'
        }
        {
            title: 'Bedrag'
            expression: 'item.cost | currency:\'€ \''
            sorter: 'cost'
        }
        {
            title: 'Referentie'
            attribute: 'reference'
            sorter: 'reference'
        }
        {
            title: 'Gecontroleerd?'
            expression: 'item.checked | boolean:"Ja":"Nee"'
            sorter: 'checked'
        }
        {
            link:
                url: '"#/manage/orders/" + (item.id)'
                text: 'Details'
                class: 'btn btn-default'
                icon: 'fa fa-list'
        }
    ]

    # Controller itself does not return meaningful results
    return undefined
