# Controller for the overview of all orders in the customers area
#
angular.module('mc.orders').controller 'OrdersController', (OrdersService, $routeParams) ->

    # get all orders visible by this user
    @orders = OrdersService.getAll()

    # Set checked to true if this order is both checked normal and custom
    @orders.$promise.then (orders) =>
        for order in orders
            order.checked = order.checked_normal? && order.checked_custom?

    # Information used to generate table of orders
    @attributes = [
        {
            title: 'Datum'
            expression: 'item.placed | date: "short"'
            sorter: 'placed'
        }
        {
            title: 'Bedrag'
            expression: 'item.cost | currency:\'€ \''
            sorter: 'cost'
        }
        {
            title: 'Referentie'
            attribute: 'reference'
            sorter: 'reference'
        }
        {
            link:
                url: '"#/orders/" + (item.id)'
                text: 'Details'
                class: 'btn btn-default'
                icon: 'fa fa-list'
        }
    ]

    # does not return
    return undefined
