# Handles the resetting of a password
#
angular.module('mc.login').controller 'ResetPasswordController', ($routeParams, UsersService, $location) ->

    # holds the new password and the repeated (re) pasword
    @pass = {}

    # True when submitting
    @submitting = false

    # submits passwords if they are equal
    @submit = =>
        if @pass.new is @pass.re
            @submitting = true
            UsersService.changePassword(@pass.new, $routeParams["token"]).$promise.then $location.path '/'

    # does not return
    return undefined
