# modules used by the customer
angular.module 'mc.customer',
[
    'mc.common'
    'mc.cart'
    'mc.category'
    'mc.configurator'
    'mc.home'
    'mc.reorder'
    'mc.keyConfigurator'
]

# modules used by the admin
angular.module 'mc.admin',
[
    'mc.common'
    'mc.advertisements'
    'mc.dashboard'
    'mc.export'
    'mc.import'
    'mc.products'
    'mc.sendType'
]

# modules used by both admin and customer
angular.module 'mc.common',
[
    'fs'
    'gettext'
    'mc.login'
    'mc.navigation'
    'mc.orders'
    'mc.users'
    'mc.language'
    'mc.files'
]

# makes sure that user is redirected to login page if not logged in
#
.factory "HttpErrorInterceptorModule", ($q, $rootScope, $window) ->
    success = (response) -> response
    error = (response) ->
        if response.status == 401
            $rootScope.$broadcast 'event:httpError', 401
            $window.location.href = '/' if not $window.location.pathname '/'
            $q.reject(response)
    (httpPromise) -> httpPromise.then success, error

# Registers the module described above
#
.config ($httpProvider) ->
    $httpProvider.responseInterceptors.push('HttpErrorInterceptorModule')
    return undefined

# Runs after each reload, makes sure that the authorization header is set if it is found in session/local storage
#
.run ($rootScope, $location, $http, Session, Globals, gettextCatalog, $locale) ->
    $http.defaults.headers.common['Authorization'] = 'Basic ' + Session.currentUser?.authData

#    $rootScope.$on '$routeChangeSuccess', (event, next, current) ->
#        $location.path '/login' if not Session.currentUser?

    Globals.language ?= 'nl_NL'
    gettextCatalog.setCurrentLanguage Globals.language
    gettextCatalog.debug = yes

    $locale = 'nl_NL'

    return undefined

# Exposes a start with function on string
# todo make a prototype file ?
#
if typeof String.prototype.startsWith isnt 'function'
    String.prototype.startsWith = (str) -> (@slice 0, str.length) == str
