# Gives a default value if input not set
#
# @example
#   'value' | default:'none'
#     -> 'value'
#
# @example
#   someUndefinedVariable | default:'none'
#     -> 'none'
#
angular.module('fs.util').filter 'default', ->
    (input, def='-') ->
        return def unless !!input
        return input
