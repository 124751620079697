# Controller for displaying and modifying details of a single product.
#
angular.module('mc.products').controller 'ProductDetailsController', (Products, $resource, $routeParams) ->

    # Fetch the user id of the user to be viewed
    id = $routeParams['id']

    # Gets the product details
    @product = Products.get(id)

    # Gets the possible product categories (to display as options)
    @options = $resource('/api/categories.json').query()

    # Sends update request to api.
    @edit = => @product.$update()

    # Toggles product activation state, and sends an update request to the server
    @toggleActive = =>
        @product.is_active = !@product.is_active
        @product.$update()

    # Controllers don't have a return value
    return undefined
