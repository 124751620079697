angular.module('fs.table').controller 'OverviewTableController', ->

    @pagination =
        currentPage: 1
        itemsPerPage: 10
        options: [10, 25, 50, 100, 250, 500]

    @predicate = 'placed'
    @descending = yes

    return undefined