# Service for converting from and to Base64 strings
#
angular.module('fs.util').factory 'Base64', ->

    keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

    new class Base64

        # Encodes the input to a base64 string
        #
        # @param input [String] The input to be encoded
        # @return [String] The base64 encoded output
        encode: (input) ->
            output = "";
            chr1 = chr2 = chr3 = "";
            enc1 = enc2 = enc3 = enc4 = "";
            i = 0;

            loop
                chr1 = input.charCodeAt i++
                chr2 = input.charCodeAt i++
                chr3 = input.charCodeAt i++

                enc1 = chr1 >> 2;
                enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                enc4 = chr3 & 63;

                if isNaN chr2
                    enc3 = enc4 = 64
                else if isNaN chr3
                    enc4 = 64

                output = output +
                keyStr.charAt(enc1) +
                keyStr.charAt(enc2) +
                keyStr.charAt(enc3) +
                keyStr.charAt(enc4);
                chr1 = chr2 = chr3 = "";
                enc1 = enc2 = enc3 = enc4 = "";
                break unless i < input.length

            output

        # Decodes the input from a base64 string
        #
        # @param input [String] The encoded string to decode
        # @return [String] The plaintext
        decode: (input) ->
            output = "";
            chr1 = chr2 = chr3 = "";
            enc1 = enc2 = enc3 = enc4 = "";
            i = 0;

            # remove all characters that are not A-Z, a-z, 0-9, +, /, or =
            base64test = /[^A-Za-z0-9\+\/\=]/g;
            if base64test.exec input
                window.alert "There were invalid base64 characters in the input text.\n" +
                    "Valid base64 characters are A-Z, a-z, 0-9, '+', '/',and '='\n" +
                    "Expect errors in decoding."

            input = input.replace /[^A-Za-z0-9\+\/\=]/g, "";

            loop
                enc1 = keyStr.indexOf(input.charAt(i++));
                enc2 = keyStr.indexOf(input.charAt(i++));
                enc3 = keyStr.indexOf(input.charAt(i++));
                enc4 = keyStr.indexOf(input.charAt(i++));

                chr1 = (enc1 << 2) | (enc2 >> 4);
                chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
                chr3 = ((enc3 & 3) << 6) | enc4;

                output = output + String.fromCharCode chr1;

                output = output + String.fromCharCode(chr2) if enc3 != 64
                output = output + String.fromCharCode(chr3) if enc4 != 64

                chr1 = chr2 = chr3 = "";
                enc1 = enc2 = enc3 = enc4 = "";

                break unless i < input.length;

            output