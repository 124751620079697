# The M&C Configurator module. * This module contains all logic for the custom product configurator.
#
# The configurator has a top level html page called index.html which will call other directives in a tree like fashion.
# To understand each directive, look at there respective documentations.
#
# The custom product configurator was designed to be used by the M&C web shop, but might in the future also be used as
# a plugin service for third parties. It is therefore important to update the documents in this class when changes are
# made.
#
angular.module 'mc.configurator', []

# Expose the the top level page at the /configurator link and link the ConfiguratorController to it.
.config ($routeProvider) ->
    $routeProvider.when '/configurator',
        controller  : 'ConfiguratorController',
        controllerAs: 'vm',
        templateUrl : 'src/mc/configurator/index.html'

    $routeProvider.when '/configurator/:type/:code/:hash',
        controller  : 'ConfiguratorController',
        controllerAs: 'vm',
        templateUrl : 'src/mc/configurator/index.html'
