# This is the REST API authentication service. It will save the set credentials in the session storage and will send the
# credentials in the Authorization header on each request.
angular.module('fs.auth').factory 'RestAuthService', (Base64, $http, Globals, Session) ->
    new class RestAuthService

        # Requests the currently logged in user.
        #
        # @param [String] user The username to check credentials of.
        # @param [String] pass The plaintext password to check credentials of.
        # @return:             A promise that is fulfilled when the api accepts the current login and returns the logged
        #                       in user.
        CheckLogin: (user, pass) ->
#            $http.post '/api/authenticate.json', {username: user, password: pass}
            $http.get '/api/users/me.json'

        # Sets the credentials for all requests from this point on. It will store the credentials in session storage and
        # sets them to the Authorization header with each request made. If the persist flag is set to true, the
        # credentials will also be stored in local storage so that they can be retrieved for the next session.
        #
        # @param [String]  user    The username to set for credentials
        # @param [String]  pass    The plaintext password to set for credentials
        # @param [Boolean] persist If set to true, the credentials will be stored to local storage in addition to the
        #                            session storage
        SetCredentials: (user, pass, persist = false) ->
            authData = Base64.encode user + ':' + pass
            Globals.currentUser = {username: user, authData: authData} if persist
            Session.currentUser = {username: user, authData: authData}
            $http.defaults.headers.common.Authorization = 'Basic ' + authData
            return undefined

        # Clears the credentials set by the SetCredentials method. Will also reset the authorization header. This method
        # will clear the credentials from both the session storage as well as the local storage.
        ClearCredentials: ->
            delete Globals.currentUser if Globals.currentUser?
            delete Session.currentUser if Session.currentUser?
            $http.defaults.headers.common.Authorization = 'Basic ';
            return undefined
