# Directive for a single extra key. This will consists of a label and a simple drop down menu. since the extra key can
# be removed, it should request the removal by calling remove() so that the parent scope can handle it's removal.
#
angular.module('mc.configurator').directive 'mcExtraKey', ->
    templateUrl: 'src/mc/configurator/mc-extra-key.html'
    restrict: 'E'
    scope:
        subject: '='
        remove: '&'
        changed: '&'
