# Used to manipulate the current user
#
angular.module('mc.users').factory 'UsersService', ($resource, $location) ->
    new class UsersService

        # $resource object for user api
        resource = $resource '/api/users/:id.json',
            {id: '@id'},
            self:
                method: 'GET'
                params:
                    id: 'me'
            save:
                method: 'PUT'
            create:
                method: 'POST'
            changePassword:
                method: 'PUT'
                params:
                    id: 'me'
                    changePassword: true
            changePasswordUsingToken:
                method: 'POST'
                params:
                    newPassword: '@newPassword'
                    ott: '@token'
            resetPassword:
                method: 'POST'
                params:
                    resetPassword: '@resetPassword'
            allCustomers:
                method: 'GET'
                isArray: yes
                params:
                    role: 'customer'


        # Gets the user with the given id
        #
        # @return ($resource) the requested user
        #
        get: (id) ->
            resource.get({id: id})

        # Gets the currently logged in user
        #
        # @return ($resource) the currently logged in user
        #
        getLoggedInUser: ->
            resource.self()

        # Get all customers
        #
        # @return [$resource] All users
        getAllCustomers: ->
            resource.allCustomers()

        # Changes the password using token. User is identified by the given token
        #
        # @param  [String] newPass The new password to set
        # @param  [String] token   The identifying token for the user (set when lost password dialog is finished)
        # @return [$resource]      The api result
        changePassword: (newPass, token) ->
            if (token?)
                resource.changePasswordUsingToken({newPassword: newPass, token: token})
            else
                resource.changePassword({newPassword: newPass})

        # Emails a password reset token to the given email address
        #
        # @param  [String] mailAddress The email address to send the password to
        # @return [Promise]          The api result
        mailResetToken: (mailAddress) -> resource.resetPassword({resetPassword: mailAddress}).$promise

        # Creates a new user using the given values
        #
        # @param  [Object] values The values to use in the new user object
        # @param  [String] relation_number The users id in Syntess (not db id)
        # @option [String] company Users are companies, this should be it's name
        # @option [String] contact_person Company contact person
        # @option [String] postal_code
        # @option [Number] house_number
        # @option [String] house_number_addition
        # @option [String] city
        # @option [String] country
        # @option [String] phone
        # @option [String] mail
        # @option [Boolean] can_edit_address If set to true, has the ability to enter a custom shipping address
        # @option [Boolean] can_place_order_collection If set to true, has the capability to place order collections
        # @option [Boolean] can_order_pr08 If set to true, has the capability to order pr08 profiles
        # @option ["customer"|"administrator"] role Either a customer or an administrator
        # @return [$resource] Newly created user
        newUser: (values) -> resource.create {}, values

        # Removes the user and all related orders (!) from the database.
        #
        # @param  [Number] id The Database id of this user
        # @return [Promise] An empty promise (pun intended) that gets fulfilled when the user is deleted
        remove: (id) ->
            resource.delete {id: id}
