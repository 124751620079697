# Module for displaying the home url
#
angular.module 'mc.home', ['ngRoute', 'ngResource', 'ngSanitize', 'fs.callout', 'fs.persistence']

.config ($routeProvider) ->
    $routeProvider
    .when '/',
        controller: 'HomeController'
        controllerAs: 'vm'
        templateUrl: 'src/mc/home/index.html'
    .otherwise
        redirectTo: '/'

# Loads the customers links and displays them on the homepage
#
.controller 'HomeController', ($resource, $http, Session) ->
    @linksets = $resource('assets/links-customer.json').query()
    @ad = ''
    @pos = 0
    $http.get('/api/advertisements/' + Session.language + '.html').success (res) =>
        is_active = res.substr(0, 1) is 'y'
        @pos = if is_active then res.substr 1, 1 else -1
        @ad = res.substr 2
    return undefined

.directive 'mcHome', ->
    templateUrl: 'src/mc/home/home.html'
    restrict: 'E'
    scope:
        links: '='
        ad: '='
        adPosition: '='
        lang: '='
