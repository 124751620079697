angular.module('mc.users').controller 'AddUserController', (UsersService, MessageService, Address, $scope, $location) ->

    @account = {role: 'customer'}

    @checkPostalCode = =>
        if (@account.postal_code)
            @account.postal_code = @removePostalCodeSpace @account.postal_code
            check = Address.get @account.postal_code
            check.$promise.then \
            (res) =>
                if (res?)
                    @account.street = res.street
                    @account.city = res.city
                    @account.country = 'Nederland'
                    @addressUnknown = false
                else
                    @addressUnknown = true
            , (fail) =>
                @account.street = undefined
                @account.city = undefined
                @account.country = undefined
                @addressUnknown = true

    @checkCombinedHouseNumber = =>
        return unless @combinedHouseNumber?
        @account.house_number = @combinedHouseNumber.match(/^\d+/)[0]
        @account.house_number_addition = @combinedHouseNumber.match(/[a-zA-Z]*$/)[0]

    @removePostalCodeSpace = (code) =>
        if code.length is 6
            return code.toUpperCase()
        else if code.length is 7
            return ((code.slice 0, 4) + (code.slice 5)).toUpperCase()
        else
            return code

    @addAccount = =>
        UsersService.newUser(@account).$promise.then $location.path('/manage/users')
        MessageService.add 'Nieuwe klant aangemaakt', 'success' if @account.role == 'customer'
        MessageService.add 'Nieuwe administrator aangemaakt', 'success' if @account.role == 'administrator'

    $scope.$watch 'vm.account.mail', (newVal) => @account.username = newVal

    return undefined
