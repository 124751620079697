angular.module('mc.advertisements').controller 'AdvertisementController', ($http, MessageService) ->

    @lang = 'en_US'
    @content = {}

    @updateHtml = =>
        @content[@lang].html = '<h3>' + @content[@lang].title + '</h3>\n<p>' + @content[@lang].body + '</p>'

    @updateTitleBody = =>
        @content[@lang].title = @extractTitle @content[@lang].html
        @content[@lang].body = @extractBody @content[@lang].html

    @updateLang = => @getAdvertisement @lang


    @extractTitle = (html) => html.match(/<h[1-3]>(.*?)<\/h[1-3]>/)?[1]
    @extractBody = (html) => html.match(/<p>(.*?)<\/p>/)?[1]

    @getAdvertisement = (lang = @lang) =>
        $http.get('/api/advertisements/' + @lang + '.html').success (res) =>
            @is_edited = no
            html = res.substr 2
            @content[lang] =
                html: html
                title: @extractTitle html
                body: @extractBody html
                position: parseInt res.substr 1, 2
                is_active: res.substr(0, 1) is 'y'
                was_active: res.substr(0, 1) is 'y'

    @toggleActive = =>
        @content[@lang].is_active = not @content[@lang].is_active

    @saveHtml = =>
        @content[@lang].was_active = @content[@lang].is_active
        isActiveChar = if @content[@lang].is_active then 'y' else 'n'
        html = isActiveChar + @content[@lang].position + @content[@lang].html
        console.log html
        $http.post('/api/advertisements.html?language=' + @lang, html).success (res) =>
            @is_edited = no
            html = res.substr 2
            @content[@lang] =
                html: html
                title: @extractTitle html
                body: @extractBody html
                position: parseInt res.substr 1, 2
                is_active: res.substr(0, 1) is 'y'
                was_active: res.substr(0, 1) is 'y'
            MessageService.add 'Advertentie aangepast', 'info'

    @getAdvertisement()

    return undefined
