# Service to generate a random hexdecimal hash
#
angular.module('fs.util').factory 'Hash', ->

    # returns a random byte
    randomByte = -> Math.floor Math.random() * 255

    # Generate a new hash
    #
    # @param  [Number] bytes Number of bytes to generate has for (defaults to 20, which is SHA1 length)
    # @return [String]       Random hexdecimal string representing the number of bytes given
    #
    create: (bytes = 20) ->

        # init empty hash
        hash = ''

        # for the amount of bytes needed, add a hexdecimal number to hash
        hash += randomByte().toString(16) for i in [0..bytes]

        # return freshly created hash
        return hash
