angular.module('mc.language', [])

.controller 'LanguageController', (Globals, Session, gettextCatalog) ->

  @setLanguage = (lang) ->
    Globals.language = lang
    Session.language = lang
    gettextCatalog.setCurrentLanguage Session.language
    return undefined

  return undefined