# Exposes a single configuration in the configurator.
#
# Each configuration gets it configuration options ans selected values passed down on by the configurator scope. The
# configuration should make sure that it calls the remove() and countChanged() callbacks at the appropriate times.
#
# remove() should be called if the configuration should be removed by the parent
#
# countChanged() should be called if the number of cylinders in the configuration changes
#
angular.module('mc.configurator').directive 'mcConfiguration', ->
    templateUrl: 'src/mc/configurator/mc-configuration.html'
    restrict: 'E'
    transclude: true
    scope:
        title: '@'
        subject: '='
        lang: '='
        remove: '&'
        countChanged: '&'
        pr08: '='
        flipPr08: '&'
    controller: 'ConfigurationController'
    controllerAs: 'vm'