# The price indication directive.
#
# This will show the message given into this scope. which contains a product and price, as well as an error if
# applicable. The price indicator will show the error if possible, and otherwise the price.
#
angular.module('mc.configurator').directive 'mcPriceIndication', ->
    templateUrl: 'src/mc/configurator/mc-price-indication.html'
    restrict: 'E'
    scope:
        message: '='
    replace: true