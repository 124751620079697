# Module for displaying the home url
#
angular.module 'mc.sendType', []

.config ($routeProvider) ->
    $routeProvider

    .when '/manage/send-type',
        controller: 'SendTypeController'
        controllerAs: 'vm'
        templateUrl: 'src/mc/sendType/send-type-overview.html'

    .when '/manage/send-type/:id',
        controller: 'SendTypeDetailsController'
        controllerAs: 'vm'
        templateUrl: 'src/mc/sendType/send-type-details.html'

