# Controller for a single configuration in the configurator. This controller has the responsibility to display error
# messages when an invalid configuration is used.
#
angular.module('mc.configurator').controller 'KeyConfiguratorController',
    ($http, $scope, $rootScope, $location, Session, CartService) ->

        # holds the configurator settings, as well as selected values in configurator
        @settings = {}

        # holds the database retrieved selected item
        @selected = {waiting: yes}

        # update the @selected variable, retrieves the new values (price, etc.) from database
        @update = =>
            url = '/api/products.json?format=key-configurator'\
                    + '&profile=' + @settings.profile.selected\
                    + '&snake=' + @settings.snake.selected\
                    + '&color=' + @settings.color.selected
            @selected = {waiting: yes}
            $http.get(url)
                .success((data) => @selected = data)
                .error((error) => @selected = {error: error})

        # place the currently selected order and go to cart
        @placeOrder = =>
            CartService.add @selected.id, @settings.quantity, @selected.price, @selected.code
                .then => $location.path '/cart'

        # (private) Get the settings for this configurator from a json file
        _fetchSettings = =>
            $http.get('assets/key-configurator.json').then (selected) =>
                @settings = selected.data
                for key, value of @settings
                    @settings[key].options = @settings[key].options.map (obj) ->
                        { label: obj.label[Session.language], value: obj.value }
                @settings.quantity = 1
                @update()
                return @settings

        # Create watcher for language changes TODO/CLEANUP: create observer pattern for this in root
        $rootScope.$watch 'session.language', (newLang, oldLang) -> _fetchSettings() if newLang isnt oldLang

        # Get initial settings upon initialization
        _fetchSettings()

        # does not return
        return undefined
