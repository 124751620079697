# The controller for a single category
#
# This controller is used in all default product pages. Each page is a single category, for example "keys". This
# controller will fetch the appropiate category from the REST API. This controller also exposes an addToCart() method.
#
angular.module('mc.category').controller 'CategoryController', (Category, CartService, $routeParams) ->

        # Get the products $resource object which will resolve to a list of products
        # @type {$resource} a category resource with quantity values pre-set to 1
        #
        @products = Category.get {categoryName: $routeParams["categoryId"]},  (result) =>
            angular.forEach result, (value) =>  value.quantity = 1 # set default quantities to 1

        # Add new items to the cart
        # See CartService for more information and for arguments
        #
        @addToCart = (args...) => CartService.add args...

        return undefined
