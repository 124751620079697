# Filter for assigning meaningful display values to a boolean value
#
# @example
#   questionAnswered = true
#   questionAnswered | boolean:'Yes':'No'
#   -> 'Yes'
#
angular.module('fs.filters').filter 'boolean', ->
    (input, trueVal = 'yes', falseVal = 'no') ->
        if input then trueVal else falseVal
