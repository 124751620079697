# Controller that fetchers account and exposes it to the template.
# Also has password changing functionality
#
angular.module('mc.users').controller 'EditUserController', (UsersService, MessageService, $routeParams, $location, $modal, $scope) ->

    # Fetch the user id of the user to be viewed
    id = $routeParams['id']

    # get the user details
    @account = UsersService.get(id)

    # Edit the given user's attribute with the given value
    @edit = (val, attr) =>
        @account[attr] = val
        @account.$save()
        console.log 'account updated'
        console.log 'attr:', attr, ' val:', val
        return undefined

    @toggle = (attr) =>
        @edit !@account[attr], attr
        return undefined

    # Show the dialog in which the admin can remove the current user
    @showRemoveUserDialog = =>
        modal = $modal.open
            templateUrl: 'src/mc/users/user-delete-modal.html'
            controller: 'DeleteUserModalController as vm'
        modal.result.then => @removeUser()

    # Removes the user from the database. Including all related orders
    @removeUser = =>
        @account.$resolved = false
        @account.$delete().then -> $location.path('/manage/users')
        MessageService.add 'Gebruiker ' + @account.company + ' verwijderd', 'danger'

    # does not return
    return undefined

# Delete User Modal Controller
#
# That's it actually, a controller for the modal (pop-up) where the admin can delete the current user
#
angular.module('mc.users').controller 'DeleteUserModalController', ($modalInstance) ->

    # Cancels this modal
    @cancel = => $modalInstance.dismiss 'cancel'

    # Close this modal and resolves it (i.e. delete the user)
    @ok = => try $modalInstance.close 'ok' catch error then {} # catches a wrongly fired error in $modalInstance.close()

    # does not return
    return undefined

