angular.module('mc.products').controller 'ProductsController', ($resource) ->

    @products = $resource('/api/products.json').query()

    @attributes = [
        {
            title: 'Product code'
            attribute: 'code'
            sorter: 'code'
        }
        {
            title: 'Omschrijving'
            attribute: 'description'
            sorter: 'description'
        }
        {
            title: 'Categorie'
            expression: 'item.category.description'
            sorter: 'category.description'
        }
        {
            title: 'Prijs'
            expression: 'item.price | currency:\'€ \''
            sorter: 'price'
        }
        {
            title: 'Weight'
            expression: 'item.weight ? item.weight + " g" : ""'
            sorter: 'weight'
        }
        {
            title: 'EAN'
            attribute: 'ean13'
            sorter: 'ean13'
        }
        {
            title: 'Inactief'
            expression: 'item.is_active | boolean:"":"✕"'
            sorter: 'is_active'
        }
        {
            title: 'Out of stock'
            expression: 'item.in_stock | boolean:"":"✕"'
            sorter: 'in_stock'
        }
        {
            title: ''
            link:
                text: 'Wijzig'
                icon: 'fa fa-cog'
                class: 'btn btn-default'
                url: '"#/manage/products/" + (item.id)'
        }
    ]


    return undefined
