angular.module('mc.export').controller 'ExportController', (OrdersService, $window, $timeout, $route, Session) ->

    @orders = OrdersService.getAll({checked: true, exported: false})
    @orders.$promise.then (results) => result.$resolved = true for result in results

    @simple = yes

    @loadOrders = =>
        @orders = OrdersService.getAll({checked: true, exported: false})
        @orders.$promise.then (results) => result.$resolved = true for result in results

    _doExport = (ids) =>
        return if ids.length == 0
        $window.location =  '/api/orders/' + (ids.join ',') + '.xml?download=true&token=' + Session.currentUser?.authData

    @export = =>
        _doExport @ids()
        @orders.$resolved = no
        $timeout $route.reload, 1000

    @exportAll = =>
        _doExport @ids(yes)
        @orders = []
        $timeout $route.reload, 5000


    @ids = (all = false) =>
        @orders.reduce (x, o) ->
            x.push o.id if o.mark_for_export is true or all is true
            return x
        , []

    @showAll = no

    @toggleShowAll = =>
        @showAll = !@showAll
        options = {}
        options.checked = yes
        options.exported = no if not @showAll
        @orders.$resolved = false
        OrdersService.getAll(options).$promise.then (results) =>
            result.$resolved = true for result in results
            @orders = results


    @attributes = [
        {
            title: '✓'
            check:
                callback: @check
                check_attr: 'mark_for_export'
        }
        {
            title: 'Datum'
            expression: 'item.placed | date: "short"'
            sorter: 'placed'
        }
        {
            title: 'Bedrijf'
            expression: 'item.user.company'
            sorter: 'user.company'
        }
        {
            title: 'Bedrag'
            expression: 'item.cost | currency:\'€ \''
            sorter: 'cost'
        }
        {
            title: 'Referentie'
            attribute: 'reference'
            sorter: 'reference'
        }
        {
            title: 'Geëxporteerd?'
            expression: 'item.exported | boolean:"Ja":"Nee"'
            sorter: 'checked'
        }
    ]

    return undefined
