# Represents an address in the cart. This can be either the shipping or billing address.
#
angular.module('mc.cart').directive 'mcAddress', ->
    restrict: 'EA'
    templateUrl: 'src/mc/cart/mc-address.html'
    scope:

        # The address to represent
        content: '='

        # if set to true, this address will expose editing buttons
        isEditable: '@'

        # The title of this address
        title: '@'

        # callback when editing has been done and confirmed
        confirmed: '&'

        # callback which is called when the editing is canceled and this element should revert to it's original values.
        canceled: '&'
