angular.module('mc.files').controller 'FilesCtrl', ($routeParams, $http, FileUploader, Session) ->

    @token = Session.currentUser.authData

    class Item
        constructor: (location, type = 'file') ->
            @name = location.match(/[\w\d\s\.-]*$/)[0]
            @location = location
            @path = location.replace /[\w\d\s\.-]*$/, ''
            @type = type
            @isOpen = no

    _convertPartsToObject = (parts) ->
        retArr = []
        cumUrl = ''
        retArr.push { name: part, url: cumUrl += '/' + part } for part in parts
        return retArr

    @current = {}
    @current.path = '/' + (if $routeParams.path then $routeParams.path else '')
    @current.path = @current.path + '/' unless @current.path.substr(-1, 1) is '/'
    @current.parts = _convertPartsToObject @current.path.split('/').filter (part) -> part.length
    @current.fileSystem = (item) => item.path is @current.path
    @fileSystem = []

    @addFolder = (path) =>
        $http.post('/api/files.json?options=add=folder', {path: path}).then (res) => _update(res.data)

    @deleteItem = (item) =>
        options = if item.type is 'folder' then '?options=type=folder' else '?options=type=file'
        $http.delete('/api/files/' + item.location.substr(1) + '.json' + options).then (res) => _update(res.data)

    @moveItem = (item) =>
        options = if item.type is 'folder' then '?options=type=folder' else '?options=type=file'
        $http.put('/api/files/' + item.location.substr(1) + '.json' + options, {newName: item.new_name}).then (res) => _update(res.data)

    $http.get('/api/files.json').then (res) => _update res.data

    _update = (data) =>
        @fileSystem = []
        for folder in data.folders
            @fileSystem.push new Item folder, 'folder' unless folder is '/'
        for file in data.files
            @fileSystem.push new Item file

    @uploader = new FileUploader
        url: '/api/files.json?options=add=files,path=' + @current.path.substr(1) + '&token=' + @token
    @uploader.autoUpload = yes
    @uploader.onSuccessItem = (item, res) => _update(res)

    return undefined
