# This module loads the navigation links and uses the fs.navigation to expose the links in html
#
angular.module 'mc.navigation', []

.controller 'NavigationController', (UsersService, $resource) ->
  @links = {left: {}, top: {}}
  UsersService.getLoggedInUser().$promise.then (user) =>
    res = if user.is_admin then $resource('assets/links-admin.json') else $resource('assets/links-customer.json')
    res.query().$promise.then (links) =>
      @links.left = links[0]
      @links.top = links[1]

  return undefined
