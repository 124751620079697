# The category module is responsible for loading the simple product categories.
#
# These are products that do not need to be configured and can be bulk ordered. All simple product pages are exposed
# through /category/:id, so for example /category/keys.
#
angular.module 'mc.category', ['ngRoute']

# Expose the /category/:id pages using the CategoryController
.config ($routeProvider) ->
    $routeProvider.when '/category/:categoryId',
        templateUrl: 'src/mc/category/index.html',
        controller: 'CategoryController',
        controllerAs: 'vm'

