# Directive for an editable text field.
#
# The text field has two modi: When nog editing, it is represented by a label and when in editing mode, it will be
# represented by a input field.
#
angular.module('fs.editable').directive 'fsEditable', ->
    restrict: 'EA'
    templateUrl: 'src/fs/editable/fs-editable.html'
    scope:

        # The start of the range for calculation
        value: '='

        # The start of the range for presentation
        expression: '@'

        # Callback when enter is pressed
        submit: '&onSubmit'

        # State of this element
        editing: '='

        # hide the submit button (pressing enter will still submit)
        hideSubmit: '='
