# The message controller is responsible for exposing the message service to the template as well as removing the message
# when the close button is clicked
#
angular.module('fs.popup').controller 'MessageController', (MessageService) ->

    # exposes the MessageService to the template
    @svc = MessageService

    # Removes the message when the close button is cliced
    @close = (index) => @svc.removeByIndex(index)

    return undefined
