angular.module('mc.products').factory 'Products', ($resource) ->
    new class Products
        res = $resource '/api/products/:id.json', {},
            update:
                method: 'PUT'
                params:
                    id: '@id'
            saveCsv:
                method: 'POST'

        get: (id) -> res.get({id: id})
        saveCsv: (bodyObj) -> res.saveCsv {}, bodyObj