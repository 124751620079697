# Directive for changing password inline
#
angular.module('mc.users').directive 'mcUserEditable', ->
    templateUrl: 'src/mc/users/mc-user-editable.html'
    restrict: 'A'
    scope:
        editable: '=mcUserEditable'
        edited: '='
    link: (scope, element, attrs) ->

        scope.editing = false
        [..., last] = attrs.mcUserEditable.split '.'

        scope.edit = =>
            scope.editing = true
            scope.orginal = scope.editable
            return undefined

        scope.ok = (newVal) =>
            scope.editing = false
            scope.edited newVal, last
            return undefined

        scope.clear = =>
            scope.editable = scope.orginal
            scope.editing = false
            return undefined

        return undefined