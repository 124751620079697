angular.module('mc.export').controller 'ImportController',  ($scope, Products)->

    @upload =
        src: ''
        mapping:
            code: ''
            description: ''
            weight: ''
            price: ''

    $scope.$watch 'vm.upload.src', =>
        @upload.mapping =
            code: @upload.src.meta?.fields?[0],
            description: @upload.src.meta?.fields?[1],
            weight: @upload.src.meta?.fields?[2],
            price: @upload.src.meta?.fields?[3]

    @add = => Products.saveCsv @upload

    return undefined