angular.module('fs.readFile', [])

.directive 'fsReadFile', ->
    scope:
        fsReadFile: "="
    link: (scope, element, attributes) ->
        element.bind "change", (changeEvent) ->
            reader = new FileReader()
            reader.onload = (loadEvent) ->
                scope.$apply ->
                    scope.fsReadFile = loadEvent.target.result
            reader.readAsDataURL changeEvent.target.files[0]

.directive 'fsReadFileCsv', ->
    scope:
        fsReadFileCsv: "="
    link: (scope, element, attributes) ->
        element.bind "change", (changeEvent) ->
            reader = new FileReader()
            reader.onload = (loadEvent) ->
                scope.$apply ->
                    scope.fsReadFileCsv = (Papa.parse loadEvent.target.result, {header: yes})
            reader.readAsBinaryString changeEvent.target.files[0]