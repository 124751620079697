# Overlay service, used to give blocking messages to the user
#
#
#
angular.module('fs.popup').service 'OverlayService', ($rootScope) ->
    class OverlayService

        constructor: ->
            @body = []
            @title = ''

        @setTitle = (title) ->
            @title = title
            return this

        @clearTitle = ->
            @title = ''
            return this

        @setBody = (paragraphs...) ->
            @body = paragraphs
            return this

        @clearBody = ->
            @body = []
            return this

        @show = ->
            $rootScope.overlay =
                title: @title
                body: @body
            return this

        @setAndShow = (title, paragraphs...) -> this.setTitle(title).setBody(paragraphs...).show()

        @hide = -> this.clearTitle().clearBody().show()


