# Controller used for checking orders in admin area.
#
# Each order has to be checked by either some administrative employee (normal check) or by someone
# who makes the cylinders (custom check). This depends on whether the cylinder has been ordered via
# the configurator or via default product codes.
#
angular.module('mc.orders').controller 'CheckOrdersController',
(OrdersService, $routeParams, $scope) ->

    # type of check
    type = $routeParams['type']

    # attribute corresponding to check type
    @check_attr = 'checked_normal' if type is 'normal'
    @check_attr = 'checked_custom' if type is 'custom'

    # Get all orders that still need to be checked
    @orders = OrdersService.getAll({unchecked: type, exported: false})

    # set all orders to resolved & remove orders that are already checked for current type
    @orders.$promise.then (res) => order.$resolved = true for order in res

    # if the check_attr has a (date) value, than this cylinder is checked
    @isChecked = (order) => order[@check_attr]?

    # writes a check mark to the api for the current order
    @check = (order) =>
        order.$resolved = false
        OrdersService.check(order.id, type, order[@check_attr]).$promise.then (res) =>
            order.$resolved = true
            order[@check_attr] = 1

    # exposes pagination related variables
    @p =
        currentPage: 1
        itemsPerPage: 25

    # used for sorting
    $scope.predicate = 'placed'
    $scope.reverse = true

    # flag to indicate whether only unchecked or all not exported orders should be shown
    @showAll = no;

    # switch between show all not exported orders and only checked orders
    @toggleShowAll = =>
        @showAll = !@showAll
        if @showAll then _showAll() else _showUnchecked()


    # loads all not exported orders
    _showAll = =>
      # Get all orders that still need to be checked
      @orders = OrdersService.getAll({exported: false})
      # set all orders to resolved & remove orders that are already checked for current type
      @orders.$promise.then (res) => order.$resolved = true for order in res

    # loads only not checked orders
    _showUnchecked = =>
      # Get all orders that still need to be checked
      @orders = OrdersService.getAll({unchecked: type, exported: false})
      # set all orders to resolved & remove orders that are already checked for current type
      @orders.$promise.then (res) => order.$resolved = true for order in res

    # load unchecked orders upon initialization
    _showUnchecked()

    # attributes used to generate table in the html view
    @attributes = [
        {
            title: '✓'
            check:
                callback: @check
                check_attr: @check_attr
        }
        {
            title: 'Datum'
            expression: 'item.placed | date: "short"'
            sorter: 'placed'
        }
        {
            title: 'Bedrijf'
            expression: 'item.user.company'
            sorter: 'user.company'
        }
        {
            title: 'Bedrag'
            expression: 'item.cost | currency:\'€ \''
            sorter: 'cost'
        }
        {
            title: 'Referentie'
            attribute: 'reference'
            sorter: 'reference'
        }
        {
            link:
                text: 'Details'
                class: 'btn btn-default'
                url: '"#/manage/orders/" + (item.id)'
                icon: 'fa fa-list'
        }
    ]

    # does not return
    return undefined
